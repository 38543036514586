@use '@angular/material' as mat;
@use './np-theme.scss' as np;
@use './vars.scss' as vars;

@include mat.elevation-classes();
@include mat.app-background();

@mixin theme($theme) {
  @media (hover: hover) {
    ::-webkit-scrollbar-thumb {
      background-color: mat.get-theme-color($theme, inverse-surface);
      border-radius: vars.$search-border-radius;
    }

    ::-webkit-scrollbar {
      width: 6px;
    }
  }

  .katex-error {
    color: mat.get-theme-color($theme, error);
  }

  app-search {
    background: mat.get-theme-color($theme, surface-container-highest);

    &:focus-within {
      box-shadow: inset 0 0 0 2px mat.get-theme-color($theme, primary);
    }
  }

  .npdc-leaflet {
    &__path {
      stroke: mat.get-theme-color($theme, primary);
      fill: mat.get-theme-color($theme, primary);
      fill-opacity: 0.2;
    }

    .leaflet-control-attribution,
    .leaflet-bar,
    .leaflet-bar a {
      background: mat.get-theme-color($theme, surface-container);
      color: mat.get-theme-color($theme, on-surface);
      border-color: mat.get-theme-color($theme, outline);

      &.leaflet-disabled {
        color: mat.get-theme-color($theme, inverse-on-surface);
      }
    }

    .leaflet-container {
      background: mat.get-theme-color($theme, surface);
    }
  }

  .npdc-outline {
    border: 1px solid;
    border-color: mat.get-theme-color($theme, outline);
    border-radius: vars.$container-border-radius;
    overflow: hidden;
  }

  mat-toolbar,
  .mat-drawer {
    background: mat.get-theme-color($theme, surface-container);
  }

  body {
    background: mat.get-theme-color($theme, surface);
  }

  pre {
    background: mat.get-theme-color($theme, secondary-container);
  }

  a:not(
      [class^='mdc'],
      [class^='mat'],
      [class^='leaflet'],
      [class^='npdc-leaflet']
    ) {
    color: mat.get-theme-color($theme, primary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .npdc-error-icon {
    @include mat.icon-color($theme, $color-variant: error);
  }

  .npdc-breadcrumb {
    @include mat.button-overrides(
      (
        text-label-text-color: mat.get-theme-color($theme, outline),
      )
    );

    &__active {
      @include mat.button-overrides(
        (
          text-disabled-label-text-color: mat.get-theme-color(
              $theme,
              on-surface
            ),
        )
      );
    }
  }
}

:root {
  @include mat.all-component-themes(np.$light-theme);

  @include theme(np.$light-theme);
  @media (prefers-color-scheme: dark) {
    @include mat.all-component-colors(np.$dark-theme);
    @include theme(np.$dark-theme);
  }
}

html,
body {
  height: 100%;
  width: 100%;

  @media (prefers-color-scheme: dark) {
    color-scheme: dark;
  }
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

code,
pre {
  font-family: 'Roboto Mono', monospace;
  border-radius: vars.$container-border-radius;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  padding: 1em;
}

// material overrides

// prevents long dialog titles from wrapping
.mat-mdc-dialog-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

// allows wrapping of tabs (default is vertical scroll)
.mat-mdc-tab-labels {
  display: flex !important;
  flex-wrap: wrap;
}

// add spacing between tab label icon and text
.mdc-tab__text-label > mat-icon {
  margin-right: 0.25em;
}

// npdc

.npdc-markdown {
  table {
    td {
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
  }

  overflow-x: hidden;

  img {
    @extend .npdc-outline;

    max-width: 100%;
    height: auto;
  }
}

.npdc-title {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  margin: 21.44px 0; // h1 margin

  h1 {
    margin: 0;
  }

  &__actions {
    margin: 0px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
}

.npdc-leaflet {
  overflow: hidden;

  &__cursor-position {
    padding: 0 0.5em;
  }
}
